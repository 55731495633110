//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseLayout from "@/layouts/Base";
import NotFound from "@/components/NotFound";
import ProfileAudience from "@/components/profile/Curator";
import ProfileFilmmaker from "@/components/profile/Creator";
import ProfilePicture from "@/components/profile/Image";
import {
  PROFILE_WATCHLIST_REQUEST,
  PROFILE_RECOMMENDS_REQUEST,
  PROFILE_REQUEST,
  FOLLOW_REQUEST,
} from "@/store/actions";
import { profile_service } from "@/services";
export default {
  name: "profile-page",
  components: {
    BaseLayout,
    ProfileAudience,
    ProfileFilmmaker,
    ProfilePicture,
    NotFound,
  },
  metaInfo: {
    title: "Profile",
  },
  data() {
    return {
      loading: true,
      profile: {},
      show_filmmaker_profile: false,
      recommends: [],
    };
  },
  mounted() {
    this.load_data();
  },
  computed: {
    is_my_profile() {
      return this.profile_id == "me";
    },
    profile_id() {
      return this.$route.params.id;
    },
  },
  watch: {
    $route() {
      this.load_data();
    },
    my_profile() {
      if (this.is_my_profile) this.profile = this.my_profile;
    },
  },
  methods: {
    load_data() {
      if (this.is_my_profile) {
        if (this.is_authenticated) this.load_self_data();
      } else {
        this.get_profile();
      }
    },
    load_self_data() {
      this.$store.dispatch(PROFILE_REQUEST, this.my_profile.id).then(() => {
        this.profile = this.my_profile;
      });
      this.$store.dispatch(PROFILE_WATCHLIST_REQUEST);
      this.$store.dispatch(PROFILE_RECOMMENDS_REQUEST);
      this.$store.dispatch(FOLLOW_REQUEST, {
        profile_id: this.my_profile.id,
        type: "following",
      });
      this.$store.dispatch(FOLLOW_REQUEST, {
        profile_id: this.my_profile.id,
        type: "followers",
      });
    },
    get_profile() {
      this.loading = true;
      profile_service
        .get({}, this.profile_id)
        .then((data) => {
          this.profile = data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
  },
};
