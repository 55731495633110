//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { LIST_DELETE } from "@/store/actions";
import PopupMenu from "@/components/PopupMenu";
export default {
  components: {
    PopupMenu,
  },
  props: {
    lists: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      active_list: null,
      show_menu: false,
      options: [
        // { name: "Rename", icon: "mdi-border-color", emit: "rename" },
        { name: "Delete", icon: "mdi-trash-can", emit: "delete" },
      ],
    };
  },
  methods: {
    on_option_select(option) {
      if (option.emit === "delete") this.on_delete();
      if (option.emit === "rename") this.on_rename();
    },
    on_show_menu(list) {
      this.active_list = list;
      this.show_menu = true;
    },
    on_delete() {
      this.$store.dispatch(LIST_DELETE, this.active_list);
    },
    on_rename() {},
  },
};
