//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Recommends from "@/components/profile/tabs/Recommends";
import Watchlist from "@/components/profile/tabs/Watchlist";
import ProfilePicture from "@/components/profile/Image";
import ProfileTypeSwitch from "@/components/profile/Switch";
import FollowUserList from "@/components/profile/follow/List";
import Filmography from "@/components/profile/tabs/Filmography";
import FollowBtn from "@/components/profile/FollowBtn";
import Curations from "@/components/profile/tabs/Curations";
import { follow_service, curation_service } from "@/services";
import {
  PROFILE_WATCHLIST_REQUEST,
  PROFILE_FOLLOW,
  PROFILE_UNFOLLOW,
} from "@/store/actions";
import { mapState } from "vuex";
export default {
  name: "profile-audience",
  props: {
    profile: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    Watchlist,
    ProfilePicture,
    ProfileTypeSwitch,
    Curations,
    FollowUserList,
    Filmography,
    FollowBtn,
    Recommends,
  },
  data() {
    return {
      tab: "recommends",
      curations: [],
      followers: [],
      following: [],
      hide_filmography: false,
    };
  },
  computed: {
    ...mapState("profile", {
      watchlist: (state) => state.watchlist,
    }),
    rank_txt() {
      return this.profile.curator_rank == -1 ? "-" : this.profile.curator_rank;
    },
    engagement() {
      // 6000 is the max score we are aiming at, as of now
      var score = this.my_profile.engagement_score / 6000;
      // round up to 2 decimal places
      return Math.round((score + Number.EPSILON) * 100) / 100;
    },
    profile_is_filmmaker() {
      return this.is_filmmaker(this.profile);
    },
    is_viewers_profile() {
      return this.profile.id == this.my_profile.id;
    },
    following_actions() {
      if (this.is_viewers_profile)
        return [{ name: "Unfollow", emit: "unfollow" }];
      else return [];
    },
    follower_actions() {
      if (this.is_viewers_profile)
        return [
          {
            name: "Follow Back",
            emit: "follow",
            disable: (user) =>
              this.following.filter((f) => f.id == user.id).length == 0,
          },
          {
            name: "Following",
            emit: "unfollow",
            icon: "mdi-check",
            disable: (user) =>
              this.following.filter((f) => f.id == user.id).length > 0,
          },
        ];
      else return [];
    },
  },
  watch: {
    profile() {
      this.curations.splice(0, this.curations.length);
      this.following.splice(0, this.following.length);
      this.followers.splice(0, this.followers.length);
      this.load_data();
    },
    profile_is_filmmaker() {
      // when user is not a filmmaker, filmography tab is not mounted and on_empty_filmography is never called to switch the tab,
      // in such cases explicitly setting tab to next mounted tab

      if (this.is_viewers_profile) {
        this.tab = "watchlist";
        console.log("watchlist");
      } else {
        this.tab = "recommends";
        console.log("recommends");
      }
    },
  },
  mounted() {
    this.load_data();
  },
  methods: {
    on_empty_filmography() {
      this.hide_filmography = true;
      this.tab = "watchlist";
    },
    load_data() {
      if (this.profile.id && this.is_authenticated) {
        if (this.is_viewers_profile) this.get_watchlist();
        this.get_followers();
        this.get_following();
        this.get_curations();
      }
    },
    get_level_name() {
      var level_map = {
        1: "Cinephile",
        2: "Curator",
        3: "Influencer",
        4: "Connoisseur",
        5: "Patron",
      };
      return level_map[this.profile.level];
    },
    get_review_count() {
      //TODO: fetch top reviews that I have given to movies
      return "-";
    },

    get_watchlist() {
      this.$store.dispatch(PROFILE_WATCHLIST_REQUEST);
    },
    get_followers() {
      follow_service.get({}, `${this.profile.id}/followers`).then((data) => {
        this.followers.push(...data.results);
      });
    },
    get_following() {
      follow_service.get({}, `${this.profile.id}/following`).then((data) => {
        this.following.push(...data.results);
      });
    },
    get_curations() {
      curation_service.get({ owner__id: this.profile.id }).then((data) => {
        this.curations.push(...data.results);
      });
    },
    on_list_select(list) {
      this.$router.push({
        name: "list-detail",
        params: { id: list.id, slug: this.slugify(list.name) },
      });
    },
    on_follow_user(user) {
      this.$store.dispatch(PROFILE_FOLLOW, user);
    },
    on_unfollow_user(user) {
      this.$store.dispatch(PROFILE_UNFOLLOW, user);
    },
    on_level_clicked() {},
    on_rank_clicked() {
      this.$router.push({ name: "audience-leaderboard" });
    },
    on_reviews_clicked() {
      this.$router.push({
        name: "reviews",
        params: {
          id: this.profile.id,
        },
      });
    },
  },
};
