import { render, staticRenderFns } from "./Image.vue?vue&type=template&id=0dfe53fa&"
import script from "./Image.vue?vue&type=script&lang=js&"
export * from "./Image.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QAvatar,QBtn,QFile,QDialog,QCard,QCardSection,QCardActions,Ripple,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QAvatar,QBtn,QFile,QDialog,QCard,QCardSection,QCardActions})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {Ripple,ClosePopup})
