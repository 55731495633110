//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    user: {
      type: Object,
      default() {
        return {};
      },
    },
    actions: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    enabled_actions() {
      return this.actions.filter((a) => !a.disable || a.disable(this.user));
    },
  },
  methods: {},
};
