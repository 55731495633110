//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FollowItem from "@/components/profile/follow/Item";
export default {
  props: {
    emptyTitle: {
      type: String,
    },
    emptyDesc: {
      type: String,
    },
    emptyIcon: {
      type: String,
    },
    emptyImage: {
      type: String,
    },
    users: {
      type: Array,
      default() {
        return [];
      },
    },
    actions: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  components: { FollowItem },
  methods: {
    on_action(val) {
      this.$emit(val.action.emit, val.user);
    },
  },
};
