//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ProfilePicture from "@/components/profile/Image";
import ProfileTypeSwitch from "@/components/profile/Switch";
import Reviews from "@/components/Reviews";
import FollowUserList from "@/components/profile/follow/List";
import Filmography from "@/components/profile/tabs/Filmography";
import Crew from "@/components/profile/tabs/Crew";
import FollowBtn from "@/components/profile/FollowBtn";
import { review_service, follow_service } from "@/services";
import {
  FOLLOW_REQUEST,
  PROFILE_FOLLOW,
  PROFILE_UNFOLLOW,
} from "@/store/actions";
export default {
  name: "profile-filmmaker",
  props: {
    profile: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  components: {
    ProfilePicture,
    ProfileTypeSwitch,
    Reviews,
    FollowUserList,
    Filmography,
    Crew,
    FollowBtn,
  },
  data() {
    return {
      new_followers: [],
      new_following: [],
      reviews: [],
      tab: "filmography",
      xp_info_dialog: false,
    };
  },
  computed: {
    fund_meter() {
      // 6000 is the max score we are aiming at, as of now
      var score = this.profile.pop_score / 6400;
      // round up to 2 decimal places
      return Math.round((score + Number.EPSILON) * 100) / 100;
    },
    followers() {
      if (this.is_viewer_profile) return this.$store.state.follow.followers;
      else return this.new_followers;
    },
    following() {
      if (this.is_viewer_profile) return this.$store.state.follow.following;
      else return this.new_following;
    },
    profile_is_filmmaker() {
      return this.is_filmmaker(this.profile);
    },
    show_login_popup() {
      return !this.is_authenticated;
    },
    is_viewer_profile() {
      return this.profile.id == this.my_profile.id;
    },
    rank_txt() {
      return this.profile.creator_rank == -1 ? "-" : this.profile.creator_rank;
    },
    following_actions() {
      if (this.is_viewer_profile)
        return [{ name: "Unfollow", emit: "unfollow" }];
      else return [];
    },
    follower_actions() {
      if (this.is_viewer_profile)
        return [
          {
            name: "Follow Back",
            emit: "follow",
            disable: (user) =>
              this.following.filter((f) => f.id == user.id).length == 0,
          },
          {
            name: "Following",
            emit: "unfollow",
            icon: "mdi-check",
            disable: (user) =>
              this.following.filter((f) => f.id == user.id).length > 0,
          },
        ];
      else return [];
    },
  },
  watch: {
    profile() {
      this.reviews.splice(0, this.reviews.length);
      this.new_followers.splice(0, this.new_followers.length);
      this.new_following.splice(0, this.new_following.length);
      this.load_data();
    },
  },
  mounted() {
    this.load_data();
  },
  methods: {
    load_data() {
      if (this.profile.id && this.is_authenticated) {
        this.get_reviews();
        this.get_followers();
        this.get_following();
      }
    },
    show_xp_info_dialog() {
      this.xp_info_dialog = true;
    },
    on_rank_click() {
      this.$router.push({ name: "filmmaker-leaderboard" });
    },
    on_films_click() {
      this.$router.push({
        name: "movies-by-profile",
        params: { profile_id: this.profile.id },
      });
    },
    on_follow_user(user) {
      this.$store.dispatch(PROFILE_FOLLOW, user);
    },
    on_unfollow_user(user) {
      this.$store.dispatch(PROFILE_UNFOLLOW, user);
    },
    get_reviews() {
      review_service
        .get({ author__id: this.profile.id })
        .then((res) => {
          this.reviews = res.results;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    get_followers() {
      if (this.is_viewer_profile) {
        this.$store.dispatch(FOLLOW_REQUEST, {
          profile_id: this.profile.id,
          type: "followers",
        });
      } else {
        follow_service.get({}, `${this.profile.id}/followers`).then((data) => {
          this.new_followers.push(...data.results);
        });
      }
    },
    get_following() {
      if (this.is_viewer_profile) {
        this.$store.dispatch(FOLLOW_REQUEST, {
          profile_id: this.profile.id,
          type: "following",
        });
      } else {
        follow_service.get({}, `${this.profile.id}/following`).then((data) => {
          this.new_following.push(...data.results);
        });
      }
    },
  },
};
