import { render, staticRenderFns } from "./Curator.vue?vue&type=template&id=c8da225e&"
import script from "./Curator.vue?vue&type=script&lang=js&"
export * from "./Curator.vue?vue&type=script&lang=js&"
import style0 from "./Curator.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QBtn,QLinearProgress,QIcon,QCard,QTabs,QTab,QSeparator,QTabPanels,QTabPanel} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QBtn,QLinearProgress,QIcon,QCard,QTabs,QTab,QSeparator,QTabPanels,QTabPanel})
