//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import { PROFILE_IMAGE_UPDATE } from "@/store/actions";
export default {
  props: {
    editable: {
      type: Boolean,
      default: true,
    },
    profile: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    VueCropper,
  },
  data() {
    return {
      change_icon_dialog: false,
      profile_image: {
        file: null,
        error: "",
        loading: false,
      },
    };
  },
  watch: {
    profile_image_url() {
      // called when the select file has changed then reset the error
      this.profile_image.error = "";
      if (this.profile_image.file) this.change_icon_dialog = true;
    },
  },
  computed: {
    profile_image_url() {
      if (this.profile_image.file)
        return URL.createObjectURL(this.profile_image.file);
      return this.profile.image;
    },
  },
  methods: {
    on_profile_image_reject() {
      this.profile_image.error = "Select an image";
    },
    on_change_icon() {
      this.$refs.file_select.$el.click();
    },
    save_profile_image() {
      this.profile_image.loading = true;
      this.$refs.cropper
        .getCroppedCanvas({
          width: 400,
          height: 400,
        })
        .toBlob((blob) => {
          this.$store
            .dispatch(PROFILE_IMAGE_UPDATE, blob)
            .then(() => {
              this.profile_image.loading = false;
              this.profile_image.file = null;
              this.change_icon_dialog = false;
            })
            .catch((error) => {
              this.profile_image.loading = false;
              // TODO: show error message wither on the popup or seperately
              this.change_icon_dialog = false;
              console.log(error);
            });
        }, "image/png");
    },
  },
};
